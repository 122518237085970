<template>
<div class="card-page">
  <div class="card-router">
    <span><router-link :to="{name: 'cardServices'}">卡系服务</router-link></span>
    <span><router-link :to="{name: 'cardRecords'}">开卡详情</router-link></span>
  </div>
  <router-view></router-view>
</div>
</template>

<style lang="less" scoped>
.card-router {
  padding: 10px 20px;
  font-size: 16px;
  span {
    margin-right: 16px;
    a {
      color: #000;
      &.active {
        color: #fdad00;
      }
    }
  }
}
</style>